import actionCreatorFactory from 'typescript-fsa';

import { JSObject } from 'types/Common';

const actionCreator = actionCreatorFactory('Abc');

// Apple Business Connect OAuth認証情報
export type CreateABCAccountParams = {
  // 値がnullでない場合、再認証したいアカウントのID
  accountId: number | null;
  // OAuthアプリ連携画面のコールバックURLに含まれるトークン取得用のコード
  code: string;
  // OAuthアプリ連携開始時に生成した文字列
  codeVerifier: string;
};

/** Apple Business Connect連携関係のアクション */
export const AbcActions = {
  // 店舗のABC連携 組織に紐づくカンパニーのロケーション一覧の取得
  getLocations: actionCreator('getLocations'),

  // アカウント
  getAccounts: actionCreator('getAccounts'),
  setAccounts: actionCreator<JSObject[]>('setAccounts'),
  removeAccount: actionCreator<number>('removeAccount'),
  createAccount: actionCreator<CreateABCAccountParams | null>('createAccount'),

  // 店舗のABC連携 連携・連携解除
  connect: actionCreator<{ storeId: number; accountId: number; locationId: string }>('connect'),
  disconnect: actionCreator<number>('disconnect'),
};
