import React, { useCallback, useMemo } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { z } from 'zod';

import { Button } from 'components/atoms/Button';
import { Counter } from 'components/atoms/Counter';
import { FormContent } from 'components/atoms/FormContent';
import { Input } from 'components/atoms/Input';
import { PullDown } from 'components/atoms/PullDown';
import { TextArea } from 'components/atoms/TextArea';
import { ServiceHelp as Help } from 'helpers/ContextHelp';
import { isNonNegativeInteger } from 'helpers/utils';
import {
  MAX_SERVICE_DESCRIPTION_LENGTH,
  MAX_SERVICE_NAME_LENGTH,
  PRICE_TYPE_LABEL,
  Price,
  PriceType,
  Service,
} from 'models/Domain/Service/Service';
import { COLOR } from 'style/color';
import { SelectOption } from 'types/Common';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  service: Service;
  onChange: (service: Service) => void;
  onDelete: () => void;
};

type FormInputs = {
  name: Service['name'];
  priceValue: string;
  priceType: PriceType;
  description: Service['description'];
};

const options: SelectOption<PriceType>[] = [
  { value: 'NO_PRICE', text: PRICE_TYPE_LABEL['NO_PRICE'] },
  { value: 'FREE', text: PRICE_TYPE_LABEL['FREE'] },
  { value: 'CONSTANT', text: PRICE_TYPE_LABEL['CONSTANT'] },
];

export const serviceEditSchema = z
  .object({
    name: z
      .string()
      .max(MAX_SERVICE_NAME_LENGTH, `サービス名は${MAX_SERVICE_NAME_LENGTH}文字以内で入力してください`)
      .nonempty('サービス名は必須です'),
    priceType: z.enum(['CONSTANT', 'FREE', 'NO_PRICE']),
    priceValue: z.string(),
    description: z
      .string()
      .max(MAX_SERVICE_DESCRIPTION_LENGTH, `説明文は${MAX_SERVICE_DESCRIPTION_LENGTH}文字以内で入力してください`)
      .superRefine((value, ctx) => {
        // 改行を含むか
        if (/\r?\n/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: '改行は入力できません',
          });
        }
        // 全角スペースを含むか
        if (/\u3000/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: '全角スペースは入力できません',
          });
        }
        // 2つ以上の連続したスペースを含むか
        if (/ {2,}/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: '2つ以上の連続したスペースは入力できません',
          });
        }
        // 先頭または末尾にスペースがあるか
        if (/^\s|\s$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: '先頭または末尾にスペースは入力できません',
          });
        }
      }),
  })
  .superRefine((value, ctx) => {
    // priceValueとpriceTypeの組み合わせのバリデーション
    if (value.priceType === 'CONSTANT') {
      // priceValueが「固定」の場合は、priceValueは必須
      if (value.priceValue === '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: '価格は必須です',
          path: ['priceValue'],
        });
      }
      // 価格が正の整数であるか
      if (!isNonNegativeInteger(value.priceValue)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: '価格が不正です',
          path: ['priceValue'],
        });
      }
    } else {
      // priceValueが「無料」または「設定しない」の場合は、priceValueは設定できない
      if (value.priceValue !== '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: '価格は設定できません',
          path: ['priceValue'],
        });
      }
    }
  });

export const ServiceEditModal = React.memo<Props>(
  ({ isOpen, onClose, service: defaultService, onChange, onDelete }) => {
    const {
      formState: { errors, isValid, isSubmitted, defaultValues },
      control,
      handleSubmit,
      setValue,
      trigger,
      watch,
    } = useForm({
      mode: 'onSubmit',
      resolver: zodResolver(serviceEditSchema),
      defaultValues: {
        name: defaultService.name,
        priceType: defaultService.price.type,
        priceValue: `${defaultService.price.value ?? ''}`,
        description: defaultService.description,
      },
    });

    const priceType = watch('priceType');
    const priceValue = watch('priceValue');
    const description = watch('description');

    const price = useMemo(
      () =>
        new Price({
          type: priceType,
          value: priceType === 'CONSTANT' && isNonNegativeInteger(priceValue) ? parseInt(priceValue, 10) : null,
        }),
      [priceType, priceValue],
    );

    const onSubmit = useCallback(
      (data: FormInputs) => {
        onChange(
          defaultService
            .changeValue('name', data.name)
            .changeValue('price', price)
            .changeValue('description', data.description),
        );
        onClose();
      },
      [defaultService, onChange, onClose, price],
    );

    const onError = useCallback(() => {
      window.alert('入力内容に誤りがあります。入力内容をご確認ください。');
    }, []);

    const handleDelete = useCallback(() => {
      onDelete();
      onClose();
    }, [onDelete, onClose]);

    return (
      <Modal open={isOpen} onClose={onClose}>
        <ModalContent>
          <Wrapper>
            <Title>サービスの編集</Title>
            <ContentWrapper>
              <Content>
                <FormContent name={'サービス名'} required informationText={Help.serviceName}>
                  <StyledInput
                    name={'name'}
                    onChange={async (value) => {
                      setValue('name', value);
                      await trigger('name');
                    }}
                    defaultValue={defaultValues?.name}
                    error={isSubmitted && errors.name?.message}
                    disabled={!defaultService.isCustom}
                  />
                  <StyledTextCount size={watch('name').length} maxSize={MAX_SERVICE_NAME_LENGTH} />
                </FormContent>
                <FormContent name={'価格（円）'} informationText={Help.price}>
                  <FlexContainer>
                    <StyledPullDown
                      options={options}
                      value={priceType}
                      allowAdditions={true}
                      additionLabel={'固定: '}
                      onChange={async (value) => {
                        setValue('priceType', value);
                        setValue('priceValue', '');
                        await trigger('priceType');
                        await trigger('priceValue');
                      }}
                      onAddItem={async (value) => {
                        // 有効な値の場合はpriceTypeをCONSTANTに変更し値を設定、そうでなければ元の値を設定する
                        if (isNonNegativeInteger(value)) {
                          setValue('priceType', 'CONSTANT');
                          setValue('priceValue', value);
                        } else {
                          setValue('priceType', priceType);
                          setValue('priceValue', priceValue);
                        }
                        await trigger('priceType');
                        await trigger('priceValue');
                      }}
                    />
                    <Controller
                      name={'priceValue'}
                      control={control}
                      render={({ field }) => (
                        <StyledInput
                          value={field.value}
                          disabled={priceType !== 'CONSTANT'}
                          onChange={async (value) => {
                            field.onChange(value);
                            await trigger('priceValue');
                          }}
                          error={isSubmitted && errors.priceValue?.message}
                          style={{ visibility: priceType === 'CONSTANT' ? 'visible' : 'hidden' }}
                          placeholder={'価格を入力してください!'}
                        />
                      )}
                    />
                  </FlexContainer>
                </FormContent>
                <FormContent name={'説明文'} informationText={Help.description}>
                  <StyledTextArea
                    name={'description'}
                    onChange={async (value) => {
                      setValue('description', value);
                      await trigger('description');
                    }}
                    defaultValue={defaultValues?.description ?? ''}
                    error={isSubmitted && errors.description?.message}
                  />
                  <StyledTextCount
                    size={description !== null ? description.length : 0}
                    maxSize={MAX_SERVICE_DESCRIPTION_LENGTH}
                  />
                </FormContent>
              </Content>
            </ContentWrapper>
            <ButtonWrapper>
              <StyledButton onClick={handleDelete} priority={'high'} negative={true}>
                削除
              </StyledButton>
              <StyledButton onClick={onClose} style={{ marginLeft: 'auto' }}>
                キャンセル
              </StyledButton>
              <StyledButton
                onClick={handleSubmit(onSubmit, onError)}
                priority={'high'}
                disabled={isSubmitted && !isValid}
              >
                適用
              </StyledButton>
            </ButtonWrapper>
          </Wrapper>
        </ModalContent>
      </Modal>
    );
  },
);

const ModalContent = styled(Modal.Content)`
  height: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${COLOR.GRAY};
`;

const Content = styled.div`
  margin-bottom: 32px;
`;

const StyledButton = styled(Button)`
  max-width: 150px;
  width: calc(50% - 8px);
  padding: 14px 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
`;

const StyledInput = styled(Input)``;
const StyledTextArea = styled(TextArea)``;

const StyledTextCount = styled(Counter)`
  margin-top: 4px;
  text-align: right;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const StyledPullDown = styled(PullDown)`
  min-width: calc(50% - 8px);
  .ui.search.selection.dropdown {
    min-height: 54px;
  }
`;
