import { List, Record } from 'immutable';

import ErrorType from 'helpers/errorType';
import { validateEmail } from 'helpers/utils';
import { JSObject } from 'types/Common';

import { Organization } from './Organization';

export enum UserRole {
  Member = 'member',
  Sv = 'sv',
  Vmd = 'vmd',
  Admin = 'admin',
}

// 通知の種類
export type NotificationType = 'notify_review_update' | 'notify_offer_update' | 'notify_location_update';

const UserRoleOptions: { value: UserRole; text: string; disabled?: boolean }[] = [
  { value: UserRole.Admin, text: '管理者' },
  { value: UserRole.Vmd, text: '本社スタッフ' },
  { value: UserRole.Sv, text: 'SV／エリアマネージャー' },
  { value: UserRole.Member, text: '店舗スタッフ' },
];

// User→Userの編集権限有無(ロール観点)
const EditPermissions: { [key in UserRole]: { [key in UserRole]: boolean } } = {
  admin: { admin: true, vmd: true, sv: true, member: true },
  vmd: { admin: false, vmd: true, sv: true, member: true },
  sv: { admin: false, vmd: false, sv: false, member: true },
  member: { admin: false, vmd: false, sv: false, member: false },
};

// User→Userの削除権限有無(ロール観点)
// NOTE 現状は編集権限と同じなのでそのまま当てはめる
const DeletePermissions: { [key in UserRole]: { [key in UserRole]: boolean } } = EditPermissions;

// User→Userの作成権限有無(ロール観点)
const CreatePermissions: { [key in UserRole]: { [key in UserRole]: boolean } } = {
  admin: { admin: false, vmd: true, sv: true, member: true },
  vmd: { admin: false, vmd: true, sv: true, member: true },
  sv: { admin: false, vmd: false, sv: false, member: true },
  member: { admin: false, vmd: false, sv: false, member: false },
};

export type UserSortKey = 'role' | 'last_active_time' | 'name';
export type UserSortOrder = 'asc' | 'desc';

const DEFAULT_SORT_KEY: UserSortKey = 'role';
const DEFAULT_SORT_ORDER: UserSortOrder = 'desc';

export type UserSortStatusType = {
  key: UserSortKey;
  order: UserSortOrder;
};

export class UserSortStatus {
  key: UserSortKey;
  order: UserSortOrder;
  constructor(userSortStatus?: UserSortStatusType) {
    this.key = userSortStatus?.key || DEFAULT_SORT_KEY;
    this.order = userSortStatus?.order || DEFAULT_SORT_ORDER;
  }
}

export class User extends Record<{
  id: number;
  pb_id: number | null;
  last_name: string;
  first_name: string;
  email: string;
  organization_id: number | null;
  organization: Organization | null;
  profile_image_url: string;
  role: UserRole;
  /** ユーザの所属店舗の店舗ID */
  stores: List<number>;
  /** ユーザの管理店舗の店舗ID */
  managing_stores: List<number>;
  notify_review_update: boolean;
  notify_offer_update: boolean;
  notify_location_update: boolean;
  isDeleted: boolean;
  isHidden: boolean;
  mccUserId: number | null;
  last_active_time: string | null;
}>({
  id: 0,
  pb_id: null,
  last_name: '',
  first_name: '',
  email: '',
  organization_id: null,
  organization: null,
  profile_image_url: '',
  role: UserRole.Member,
  stores: List(),
  managing_stores: List(),
  notify_review_update: false,
  notify_offer_update: true,
  notify_location_update: false,
  isDeleted: false,
  isHidden: false,
  mccUserId: null,
  last_active_time: null,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    if (params.organization) {
      params.organization = Organization.fromJSON(params.organization);
    }
    if (params.stores) {
      params.stores = List(params.stores.map((store: JSObject) => store.id));
    }
    if (params.managing_stores) {
      params.managing_stores = List(params.managing_stores.map((store: JSObject) => store.id));
    }
    if (params.is_deleted) {
      params.isDeleted = params.is_deleted;
    }
    if (params.is_hidden) {
      params.isHidden = params.is_hidden;
    }
    if (params.mcc_user_id) {
      params.mccUserId = params.mcc_user_id;
    }
    super(params);
  }

  get isExist() {
    return Boolean(this.id);
  }

  get fullName() {
    const _fullName = !this.last_name && !this.first_name ? '' : `${this.last_name} ${this.first_name}`;
    return this.isDeleted ? _fullName + '（削除済み）' : _fullName;
  }

  get isAdminUser() {
    return this.role === UserRole.Admin;
  }

  get isVmdUser() {
    return this.role === UserRole.Vmd;
  }

  get isSvUser() {
    return this.role === UserRole.Sv;
  }

  get isMemberUser() {
    return this.role === UserRole.Member;
  }

  get isCompanyUser() {
    return this.isAdminUser || this.isVmdUser || this.isSvUser;
  }

  get isValid() {
    const validates = this.validate();
    return Object.keys(validates).length === 0;
  }

  /**
   * MCCユーザーか
   */
  get isMccUser() {
    // mccUserIdに値があるか
    return !!this.mccUserId;
  }

  get roleLabel() {
    return UserRoleOptions.find((option) => option.value === this.role);
  }

  /**
   * 店舗を削除することが可能なユーザーか
   */
  get canDeleteStore() {
    return this.isAdminUser || this.isVmdUser;
  }

  /**
   * CSVでまとめて更新が可能なユーザか
   */
  get canUseCSVImport() {
    return this.isAdminUser || this.isVmdUser;
  }

  /**
   * Googleビジネスプロフィールの緯度経度を取り込めるユーザか
   */
  get canImportLatlng() {
    return this.isAdminUser || this.isVmdUser;
  }

  /**
   * 店頭在庫画面を利用できるユーザか
   */
  get canUseLocalInventoryPage() {
    return this.isAdminUser || this.organization?.canUseLocalInventoryPage() === true;
  }

  /**
   * 店頭在庫で「見本展示のみ」を利用できるユーザか
   */
  get canUseOnDisplayToOrder() {
    return this.organization?.canUseOnDisplayToOrder() === true;
  }

  /**
   * OMOインサイト画面を利用可能なユーザか
   */
  get canUseOmoInsight() {
    return this.isAdminUser || (this.isVmdUser && this.organization?.canUseOmoInsight() === true);
  }

  /**
   * 検索キーワード画面を利用可能なユーザか
   */
  get canUseSearchKeywords() {
    return this.isAdminUser || (this.isVmdUser && this.organization?.canUseSearchKeywords() === true);
  }

  /**
   * 検索順位監視画面を利用可能なユーザか
   */
  get canUseMapSearchRank() {
    return this.isAdminUser || this.organization?.canUseMapSearchRank() === true;
  }

  /**
   * 検索順位監視設定画面を利用可能なユーザか
   */
  get canUseMapSearchRankConfig() {
    return this.isAdminUser || (this.isVmdUser && this.canUseMapSearchRank);
  }

  /**
   * 検索順位調査画面を利用可能なユーザか
   */
  get canUseMapCompetitorResearch() {
    return this.isAdminUser || this.organization?.canUseMapCompetitorResearch() === true;
  }

  /**
   * 競合店舗を登録可能なユーザか
   */
  get canRegisterCompetitor() {
    return this.isAdminUser || ((this.isVmdUser || this.isSvUser) && this.organization?.canUseCompetitor() === true);
  }

  /**
   * メニュー画面を利用可能なユーザーか
   */
  get canUseMenu() {
    return this.isAdminUser || this.organization?.canUseMenu() === true;
  }

  /**
   * サービス画面を利用可能なユーザーか
   */
  get canUseService() {
    return this.isAdminUser || this.organization?.canUseService() === true;
  }

  /**
   * GBPパフォーマンス画面を登録可能なユーザか
   */
  get canUseGbpPerformance() {
    return this.isAdminUser || (this.isVmdUser && this.organization?.canUseGbpPerformance() === true);
  }

  /**
   * 検索ボリューム調査画面を利用可能なユーザか
   */
  get canUseSearchVolume() {
    return this.isAdminUser;
  }

  /**
   * Instagram連携機能を利用可能なユーザーか
   */
  get canUseInstagram() {
    return this.isAdminUser || (this.isVmdUser && this.organization?.canUseInstagram() === true);
  }

  /**
   * 投稿文の自動生成を利用可能なユーザーか
   */
  get canUseLocalPostAi() {
    return this.isAdminUser || this.organization?.canUseLocalPostAi() === true;
  }

  /**
   * 投稿の翻訳機能を利用可能なユーザーか
   */
  get canUseLocalPostTranslation() {
    return (
      (this.isAdminUser || this.organization?.canUseLocalPostTranslation() === true) &&
      this.organization?.hasLocalPostTranslationLanguages() === true
    );
  }

  /**
   * クチコミの自動生成を利用可能なユーザーか
   */
  get canUseReviewAi() {
    return this.isAdminUser || this.organization?.canUseReviewAi() === true;
  }

  /**
   * アカウント複製を利用可能なユーザーか
   */
  get canUseDuplicateGbpAccount() {
    return this.isAdminUser && this.email.endsWith('@pathee.com');
  }

  /**
   * Yahoo! プレイス アカウントを利用可能なユーザか
   */
  get canUseAccountForYahooPlace() {
    return this.organization?.canUseYahooPlace() === true && (this.isAdminUser || this.isSvUser || this.isVmdUser);
  }

  /**
   * Apple Business Connect を利用可能なユーザか
   */
  get canUseAccountForAppleBusinessConnect() {
    return (
      this.organization?.canUseAppleBusinessConnect() === true && (this.isAdminUser || this.isSvUser || this.isVmdUser)
    );
  }

  isSameUser(user: User) {
    return this.id === user.id;
  }

  changeLastName(last_name: string) {
    return this.set('last_name', last_name);
  }

  changeFirstName(first_name: string) {
    return this.set('first_name', first_name);
  }

  changeEmail(email: string) {
    return this.set('email', email);
  }

  changeStores(storeIds: number[]) {
    return this.set('stores', List(storeIds));
  }

  changeManagingStores(storeIds: number[]) {
    return this.set('managing_stores', List(storeIds));
  }

  changeProfileImageUrl(profile_image_url: string) {
    return this.set('profile_image_url', profile_image_url);
  }

  changeRole(role: UserRole) {
    const updateParams = { role, stores: List(), managing_stores: List() };
    if (this.role == UserRole.Sv && role == UserRole.Member) {
      // Sv → Memberの場合、店舗を載せ替える
      updateParams.stores = this.managing_stores;
    } else if (this.role == UserRole.Member && role == UserRole.Sv) {
      // Member → Svの場合、店舗を載せ替える
      updateParams.managing_stores = this.stores;
    }
    return this.merge(updateParams);
  }

  changeNotification(type: NotificationType) {
    return this.set(type, !this[type]);
  }

  changeOrganization(organization: Organization | null) {
    return this.merge({
      organization: organization,
      organization_id: organization?.id,
    });
  }

  /**
   * 設定可能なロールの選択肢を返す
   * @returns
   */
  getRoleOptions() {
    return UserRoleOptions.map((option) => ({ ...option, disabled: !this.canCreateUser(option.value) }));
  }

  validate() {
    const errors: JSObject = {};

    if (!this.last_name || !this.last_name.trim()) {
      errors.last_name = ErrorType.REQUIRED_ERROR;
    }

    if (!this.email || !this.email.trim()) {
      errors.email = ErrorType.REQUIRED_ERROR;
    }

    if (this.email && !validateEmail(this.email)) {
      errors.email = ErrorType.INVALID_EMAIL_FORMAT;
    }

    if (this.isMemberUser && this.stores.size == 0) {
      errors.store = ErrorType.REQUIRED_ERROR;
    }

    return errors;
  }

  requestParams() {
    const {
      id,
      last_name,
      first_name,
      email,
      profile_image_url,
      role,
      notify_review_update,
      notify_offer_update,
      notify_location_update,
    } = this.toObject();

    const params = {
      id: id < 1 ? null : id,
      last_name,
      first_name,
      email,
      profile_image_url,
      role,
      stores: this.stores.toArray(),
      managing_stores: this.managing_stores.toArray(),
      notify_review_update,
      notify_offer_update,
      notify_location_update,
    };

    if (this.isMemberUser) {
      params.managing_stores = [];
    } else {
      params.stores = [];
    }

    return params;
  }

  /**
   * 引数に指定されたユーザーの編集権限があるか
   * @param targetUser 対象ユーザー
   */
  canEditUser(targetUser: User) {
    // 自分自身の場合はOK
    if (this.isSameUser(targetUser)) {
      return true;
    }
    return EditPermissions[this.role][targetUser.role];
  }

  /**
   * 引数に指定されたユーザーの削除権限があるか
   * @param targetUser 対象ユーザー
   */
  canDeleteUser(targetUser: User) {
    // 自分自身の場合はNG
    if (this.isSameUser(targetUser)) {
      return false;
    }
    // 対象ユーザーがmccユーザーの場合はNG
    if (targetUser.isMccUser) {
      return false;
    }
    return DeletePermissions[this.role][targetUser.role];
  }

  /**
   * 引数に指定されたユーザーの削除権限があるか
   * @param targetRole 作成対象ユーザーのロール
   */
  canCreateUser(targetRole: UserRole) {
    return CreatePermissions[this.role][targetRole];
  }
}

interface UserListRecord {
  initialized: boolean;
  list: List<User>;
}

export class UserList extends Record<UserListRecord>({
  initialized: false,
  list: List(),
}) {
  constructor(data: JSObject[] = [], initialized = false) {
    const list = List(data.map((p) => new User(p)));
    super({ list, initialized });
  }

  getSortedList(userSortStatus: UserSortStatus) {
    switch (userSortStatus.key) {
      case 'role':
        return this.getSortedListByRole(userSortStatus.order);
      case 'name':
        return this.getSortedListByName(userSortStatus.order);
      case 'last_active_time':
        return this.getSortedListByLastActiveTime(userSortStatus.order);
      default:
        return this.list;
    }
  }

  getSortedListByRole(order: UserSortOrder) {
    const sortedList = this.list.sortBy((user) => {
      switch (user.role) {
        case UserRole.Admin:
          return 0;
        case UserRole.Vmd:
          return 1;
        case UserRole.Sv:
          return 2;
        case UserRole.Member:
          return 3;
        default:
          return 10;
      }
    });

    if (order === 'asc') {
      return sortedList.reverse();
    }

    return sortedList;
  }

  getSortedListByName(order: UserSortOrder) {
    return this.list.sort((userA, userB) => {
      const userAFullName = `${userA.last_name}${userA.first_name}` || '';
      const userBFullName = `${userB.last_name}${userB.first_name}` || '';

      if (userAFullName === '') return 1;
      if (userBFullName === '') return -1;

      if (order === 'desc') {
        return userBFullName.localeCompare(userAFullName);
      } else {
        return userAFullName.localeCompare(userBFullName);
      }
    });
  }

  getSortedListByLastActiveTime(order: UserSortOrder) {
    return this.list.sort((userA, userB) => {
      const lastLoginA = userA.last_active_time || '';
      const lastLoginB = userB.last_active_time || '';

      if (lastLoginA === '') return 1;
      if (lastLoginB === '') return -1;

      if (order === 'desc') {
        return lastLoginB.localeCompare(lastLoginA);
      } else {
        return lastLoginA.localeCompare(lastLoginB);
      }
    });
  }

  /// PullDown用の配列に変換する
  get authorizerOptions() {
    const options = this.list
      .filter((user) => user.isCompanyUser)
      .map((user) => ({ text: user.fullName, value: user.id }))
      .toArray();
    return options;
  }

  getUserswithoutTarget(targetUser: User) {
    return this.update('list', (list) => list.filter((user) => user.id !== targetUser.id));
  }

  findUser(userId: string | number) {
    return this.list.find((user) => user.id === Number(userId));
  }

  getUserIds() {
    return this.list.map((user) => user.id);
  }
}
