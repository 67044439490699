import React, { useCallback, useMemo, useState } from 'react';

import { Dayjs } from 'dayjs';
import { List as ImmutableList, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { transparentize } from 'polished';
import {
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

import { ChartTooltip } from 'components/atoms/ChartTooltip';
import { MemoIcon, legendWrapperStyle, renderActiveDot, renderLegend } from 'components/molecules/Graph';
import { getCurrentMonthStartDay, getCurrentWeekStartDay } from 'helpers/date';
import { MemoIconData, convertDateLabel, getDateLabelWithWeekOfDay } from 'helpers/graph';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { MapSearchRankGraphData } from 'models/Domain/MapSearchRank/MapSearchRank';
import { AggregateUnit } from 'models/Domain/MapSearchRank/MapSearchRankSearchCondition';
import { COLOR, UNIVERSAL_COLORS } from 'style/color';

const COLOR_SIZE = UNIVERSAL_COLORS.size;
export const INACTIVE_TRANSPARENCY = 0.8;

const getTargetDataKey = (key: string) => {
  return key.endsWith('（比較）') ? key.replace('（比較）', '') : key;
};

const getComparisonKey = (key: string) => {
  return `${key}（比較）`;
};

interface Props {
  aggregateUnit: AggregateUnit;
  startDate: Dayjs;
  endDate: Dayjs;
  comparisonStartDate: Dayjs;
  comparisonEndDate: Dayjs;
  isEnabledComparison: boolean;
  graphData: MapSearchRankGraphData;
  onClickLegendAverage?: () => void;
  onClickLegendConfig?: (configId: number) => void;
  onClickMemo?: (ids: number[]) => void;
  memoData?: MemoIconData[];
  graphSettings?: GraphSettings;
}

/** 指定された期間からデータのサイズを取得する */
export const getDataSize = (startDate: Dayjs, endDate: Dayjs, aggregateUnit: AggregateUnit): number => {
  switch (aggregateUnit) {
    case 'day':
      return endDate.diff(startDate, 'day') + 1;
    case 'week':
      return getCurrentWeekStartDay(endDate).diff(getCurrentWeekStartDay(startDate), 'week') + 1;
    case 'month':
      return getCurrentMonthStartDay(endDate).diff(getCurrentMonthStartDay(startDate), 'month') + 1;
  }
};

export const MapSearchRankGraph = React.memo<Props>(
  ({
    isEnabledComparison,
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
    graphData,
    aggregateUnit,
    onClickMemo,
    onClickLegendAverage,
    onClickLegendConfig,
    memoData,
    graphSettings = new GraphSettings({ min: 'auto', max: 'auto' }),
  }) => {
    // ホバーしたときにアクティブにするitemKey
    const [activeItemKey, setActiveItemKey] = useState<string | null>(null);

    // 検索条件の期間から、最大のデータサイズを取得する
    const maxSize = useMemo(
      () =>
        Math.max(
          getDataSize(startDate, endDate, aggregateUnit),
          isEnabledComparison ? getDataSize(comparisonStartDate, comparisonEndDate, aggregateUnit) : 0,
        ),
      [aggregateUnit, comparisonEndDate, comparisonStartDate, endDate, isEnabledComparison, startDate],
    );

    // rechartsのdataに設定できる形式に変換する
    const composedGraphData = useMemo(() => {
      // 対象期間と比較期間の長さが一致していない場合は始端を揃える
      // 指定された期間内に欠落したデータがある場合はnullが入るので、すべてのエリア×キーワードでデータの期間は揃っている想定
      const { average, comparisonAverage, items, comparisonItems } = graphData;

      // 「店舗名×キーワード」の組み合わせごとの、日付とデータのリストのリスト
      // 例（immutableではなくした場合）
      /*
        [
          // A. 集計期間のデータ
          [{itemDate: "2022年1月1日", "Pathee×AAA": 1}, {itemDate: "2022年2月1日", "Pathee×AAA": 2}],
          [{itemDate: "2022年1月1日", "Pathee×BBB": 1}, {itemDate: "2022年2月1日", "Pathee×BBB": 2}],
          // B. 比較期間のデータ
          [{comparisonDate: "2021年1月1日", "Pathee×AAA（比較）": 1}, {comparisonDate: "2021年2月1日", "Pathee×AAA（比較）": 2}],
          [{comparisonDate: "2021年1月1日", "Pathee×BBB（比較）": 1}, {comparisonDate: "2021年2月1日", "Pathee×BBB（比較）": 2}],
          // C. 集計期間の平均
          [{itemDate: "2022年1月1日", "平均": 1}, {itemDate: "2022年2月1日", "平均": 2}],
          // D. 比較期間の平均
          [{comparisonDate: "2021年1月1日", "平均（比較）": 1}, {comparisonDate: "2021年2月1日", "平均（比較）": 2}],
        ]
      */
      let data: ImmutableList<ImmutableList<any>> = ImmutableList();
      // A. 集計期間のデータ
      items.list.forEach((item) => {
        data = data.push(
          item.stats.map((stat) => {
            return {
              itemDate: convertDateLabel(stat.startDate, aggregateUnit),
              [item.key]: stat.rank,
              configId: item.configId,
            };
          }),
        );
      });
      // B. 比較期間のデータ
      isEnabledComparison &&
        comparisonItems.list.forEach((item) => {
          data = data.push(
            item.stats.map((stat) => {
              return {
                comparisonDate: convertDateLabel(stat.startDate, aggregateUnit),
                [getComparisonKey(item.key)]: stat.rank,
                configId: item.configId,
              };
            }),
          );
        });
      // C. 集計期間の平均
      if (average) {
        data = data.push(
          average.stats.map((stat) => ({
            itemDate: convertDateLabel(stat.startDate, aggregateUnit),
            平均: stat.rank,
            configId: 0,
          })),
        );
      }
      // D. 比較期間の平均
      if (isEnabledComparison && comparisonAverage) {
        data = data.push(
          comparisonAverage.stats.map((stat) => ({
            comparisonDate: convertDateLabel(stat.startDate, aggregateUnit),
            [getComparisonKey('平均')]: stat.rank,
            configId: 0,
          })),
        );
      }
      // 検索条件の期間の開始日を基準とした集計期間の最初の日付
      let firstDate = startDate;
      switch (aggregateUnit) {
        case 'month':
          firstDate = getCurrentMonthStartDay(startDate);
          break;
        case 'week':
          firstDate = getCurrentWeekStartDay(startDate);
          break;
      }
      // dataをindex順に取得して、日付ごとのデータに変換する
      // X軸表示用に、firstDateにindex * aggregateUnit分足した日付をdateとして設定する
      /*
        [
          {
            "date": "2022年1月",           // X軸に表示する日付
            "itemDate": "2022年1月"        // 集計期間のデータの日付(Tooltip用)
            "comparisonDate": "2021年1月"  // 比較期間のデータの日付(Tooltip用、比較期間が指定されている場合のみ）
            "平均": 1                      // 平均のデータ（平均のデータが存在する場合のみ）
            "平均（比較）": 2               // 比較期間の平均のデータ（比較期間の平均のデータが存在する場合のみ）
            "Pathee×AAA": 1               // 店舗名×キーワード
            "Pathee×AAA（比較）": 1        // 店舗名×キーワード（比較期間が指定されている場合のみ）
            "Pathee×BBB": 1               // 店舗名×キーワード
            "Pathee×BBB（比較）": 1        // 店舗名×キーワード（比較期間が指定されている場合のみ）
          },
          {
            "date": "2022年2月",           // X軸に表示する日付
            "itemDate": "2022年2月"        // 集計期間のデータの日付(Tooltip用)
            "comparisonDate": "2021年2月"  // 比較期間のデータの日付(Tooltip用、比較期間が指定されている場合のみ）
            "平均": 1                      // 平均のデータ（平均のデータが存在する場合のみ）
            "平均（比較）": 2               // 比較期間の平均のデータ（比較期間の平均のデータが存在する場合のみ）
            "Pathee×AAA": 1               // 店舗名×キーワード
            "Pathee×AAA（比較）": 1        // 店舗名×キーワード（比較期間が指定されている場合のみ）
            "Pathee×BBB": 1               // 店舗名×キーワード
            "Pathee×BBB（比較）": 1        // 店舗名×キーワード（比較期間が指定されている場合のみ）
          },
        ]
       */
      return ImmutableList([...Array(maxSize)])
        .map((_, index) => {
          return data.reduce(
            (result, d) => result.merge(d.get(index) ?? ImmutableMap()),
            ImmutableMap({
              date: convertDateLabel(firstDate.add(index, aggregateUnit), aggregateUnit),
              itemDate: convertDateLabel(firstDate.add(index, aggregateUnit), aggregateUnit),
            }),
          );
        })
        .toJS();
    }, [graphData, isEnabledComparison, startDate, aggregateUnit, maxSize]);

    const colors = useMemo(() => {
      // グラフのデータ数は可変なので、データ数がCOLOR_SIZEを超えた場合は同じ色が設定される
      // （見づらくなるのでそもそもデータをそんなに多く選択されないはず）
      return UNIVERSAL_COLORS.toArray();
    }, []);

    // データのkeyのリスト
    const itemKeys = useMemo(() => {
      // 「店舗名×キーワード」を追加
      let result = graphData.items.list.map((item) => item.key);
      // 平均が含まれる場合は「平均」を追加
      if (graphData.average && graphData.average.stats.size > 0) {
        result = result.insert(0, '平均');
      }
      return result;
    }, [graphData]);

    const hasAverage = graphData.average != null || graphData.comparisonAverage != null;

    const renderTooltip = useCallback(
      (data: TooltipProps<number, string>) => {
        const items = data.payload ?? [];
        return (
          <ChartTooltip>
            <ChartTooltip.Title>{getDateLabelWithWeekOfDay(data.label)}</ChartTooltip.Title>
            {items.map((item) => {
              const isActive = activeItemKey == null || activeItemKey == item.name;
              const textColor = transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, COLOR.BLACK);
              return (
                <ChartTooltip.Item key={item.dataKey}>
                  <ChartTooltip.Label color={item.color}>{item.name}</ChartTooltip.Label>
                  <ChartTooltip.Value color={textColor}>
                    {item.value != null ? item.value.toFixed(1) : 'ー'}
                  </ChartTooltip.Value>
                </ChartTooltip.Item>
              );
            })}
          </ChartTooltip>
        );
      },
      [activeItemKey],
    );

    const renderComparisonTooltip = useCallback(
      (data: TooltipProps<number, string>) => {
        const items = data.payload ?? [];
        // タイトル→指定期間のデータ→比較期間のデータの順番で、表示するべき内容だけを表示する
        let usedTitle = ImmutableSet<string>(); // 使用済みのタイトル
        return (
          <ChartTooltip>
            {items.map((item) => {
              const dataKey = item.dataKey;
              const title = getTargetDataKey(item.name ?? '');
              const isComparison = (item.name ?? '').includes('（比較）');
              const showTitle = !usedTitle.has(title);
              usedTitle = usedTitle.add(title);
              const isActive = activeItemKey == null || activeItemKey == title;
              const textColor = transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, COLOR.BLACK);
              return (
                <React.Fragment key={dataKey}>
                  {showTitle && <ChartTooltip.Title color={item.color}>{title}</ChartTooltip.Title>}
                  <ChartTooltip.Item key={item.dataKey}>
                    <ChartTooltip.Label color={textColor}>
                      {getDateLabelWithWeekOfDay(isComparison ? item.payload.comparisonDate : item.payload.itemDate)}
                    </ChartTooltip.Label>
                    <ChartTooltip.Value color={textColor}>
                      {item.value != null ? item.value.toFixed(1) : 'ー'}
                    </ChartTooltip.Value>
                  </ChartTooltip.Item>
                </React.Fragment>
              );
            })}
          </ChartTooltip>
        );
      },
      [activeItemKey],
    );

    const ticks = useMemo(() => {
      return graphSettings.getRankTicks(graphData.minRank, graphData.maxRank);
    }, [graphData.maxRank, graphData.minRank, graphSettings]);

    // なぜか同じ日付に複数のメモアイコンを描画しようとしてしまうので、一度表示した日付を保持しておく
    let displayedMemoDate = ImmutableSet<string>();

    const handleOnClickLegend = useCallback(
      (dataKey: string) => {
        // 平均の場合は、平均をクリックした処理
        if (dataKey === '平均') {
          onClickLegendAverage && onClickLegendAverage();
          return;
        }
        // それ以外の場合は、keyからconfigIdを取得して、テーブルを選択したときと同じ処理
        const item = graphData.items.findByKey(dataKey);
        if (item) {
          onClickLegendConfig && onClickLegendConfig(item.configId);
        }
      },
      [graphData.items, onClickLegendAverage, onClickLegendConfig],
    );

    const handleOnMouseEnter = useCallback(
      (value: string | null) => () => {
        setActiveItemKey(value);
      },
      [],
    );

    const handleOnMouseLeave = useCallback(() => {
      setActiveItemKey(null);
    }, []);

    return (
      <ResponsiveContainer minHeight={300}>
        <LineChart
          data={composedGraphData}
          margin={{ top: 48, right: 64, bottom: 16 }}
          onMouseLeave={() => setActiveItemKey(null)}
        >
          {/* テーブルより下にTooltipが表示されないようにz-indexを設定 */}
          <Tooltip
            wrapperStyle={{ zIndex: 10 }}
            content={isEnabledComparison ? renderComparisonTooltip : renderTooltip}
            filterNull={false}
            isAnimationActive={false}
          />
          <Legend
            verticalAlign={'top'}
            wrapperStyle={legendWrapperStyle}
            content={(props: any) => renderLegend(props)}
            onClick={(o: any) => handleOnClickLegend(getTargetDataKey(o.dataKey))}
          />
          <XAxis
            dataKey={'date'}
            tick={{ fontSize: 12, fontWeight: 'bold' }}
            tickMargin={8}
            interval={'equidistantPreserveStart'}
          />
          <YAxis
            reversed={true}
            tick={{ fontSize: 12, fontWeight: 'bold' }}
            tickMargin={8}
            ticks={ticks}
            interval={'preserveStart'}
            allowDecimals={false}
            allowDataOverflow={true}
            domain={graphSettings.rankDomain}
          />
          {itemKeys.map((itemKey, index) => {
            const comparisonItemKey = getComparisonKey(itemKey);
            const isAverage = itemKey === '平均';
            const dataIndex = hasAverage ? index - 1 : index;
            const color = isAverage ? '#05ccad' : colors[dataIndex % COLOR_SIZE];
            const isActive = activeItemKey == null || activeItemKey === itemKey;
            return (
              <React.Fragment key={index}>
                {itemKey && (
                  <Line
                    key={itemKey}
                    dataKey={itemKey}
                    stroke={transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, color)}
                    strokeWidth={2}
                    onMouseEnter={handleOnMouseEnter(itemKey)}
                    onMouseLeave={handleOnMouseLeave}
                    dot={{ clipDot: false }}
                    activeDot={renderActiveDot(graphSettings)}
                    cursor='pointer'
                  />
                )}
                {isEnabledComparison && comparisonItemKey && (
                  <Line
                    key={comparisonItemKey}
                    dataKey={comparisonItemKey}
                    stroke={transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, color)}
                    strokeDasharray={'3 3'}
                    strokeWidth={2}
                    onMouseEnter={handleOnMouseEnter(itemKey || null)}
                    onMouseLeave={handleOnMouseLeave}
                    dot={{ clipDot: false }}
                    activeDot={renderActiveDot(graphSettings)}
                    cursor='pointer'
                  />
                )}
              </React.Fragment>
            );
          })}
          {/*Y軸が間引かれてても順位が分かりやすくなるように、5ごとに補助線を引く*/}
          {ticks?.map((value) => <ReferenceLine key={value} y={value} ifOverflow={'hidden'} strokeDasharray={'1 1'} />)}

          {memoData?.map((item) => {
            // すでにメモを表示している日付ならスキップ
            if (displayedMemoDate.has(item.date)) {
              return null;
            }
            // メモを表示している日付リストに追加
            displayedMemoDate = displayedMemoDate.add(item.date);
            return (
              <ReferenceLine
                key={item.date}
                x={item.date}
                stroke={'#9994'}
                label={(props) =>
                  MemoIcon({
                    ...props,
                    title: getDateLabelWithWeekOfDay(item.date),
                    content: item.contents,
                    onClick: () => onClickMemo && onClickMemo(item.ids),
                  }) as any
                }
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    );
  },
);

export const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

export const LoadingWrapper = styled.div`
  background: ${COLOR.BACKGROUND};
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
